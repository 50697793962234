import { lazy, Suspense, useEffect, useMemo } from 'react';
import './i18n';
import {
  appLoadingStatuses,
  AppNames,
  deleteCartDataPoints,
  getKpsCart,
  globalRoutes,
  platformToast,
  publishKpsCart,
  userPermissions,
} from '@cfacorp/ctrl-platform-ui-core-utils';
import RouteInfo from '@cfacorp/ctrl-platform-ui-core-utils/dist/types/RouteInfo';
import { useSelectedStoreNumber } from '@cfacorp/ctrl-platform-shared-react-components';
import { useQueryClient } from '@tanstack/react-query';
import RouteConstants from './routes/RouteConstants';
import useHasKpsPerms from './hooks/useHasKpsPerms';
import spcApiService from './services/spcApiService';
import createBackupPayload from './utils/createBackupPayload';

const AppRouter = lazy(() => import('./routes/AppRouter'));

export default function Root() {
  const selectedStoreNumber = useSelectedStoreNumber();
  const queryClient = useQueryClient();
  const { hasKpsPerms, permsLoaded } = useHasKpsPerms();
  const routes: RouteInfo[] = useMemo(
    () => [
      {
        route: `${RouteConstants.BASE_NAME}`,
        displayName: 'KPS Home',
        keywords: ['KPS'],
      },
      {
        route: `${RouteConstants.BASE_NAME}${RouteConstants.GLOBAL_SETTINGS}`,
        displayName: 'Global Settings',
        keywords: [
          'global',
          'kps',
          'settings',
          'bump',
          'print',
          'special item',
          'sub-item',
          'label',
          'font',
          'text',
          'size',
          'order',
          'service',
          'receipt',
          'bump bar',
          'transaction',
          'status',
          'state',
          'start',
          'time',
          'auto',
          'expire',
        ],
      },
      {
        route: `${RouteConstants.BASE_NAME}${RouteConstants.ACTIVITY_LEVELS}`,
        displayName: 'Activity Levels',
        keywords: ['activity', 'level', 'busy', 'rush', 'slow', 'rout'],
      },
      {
        route: `${RouteConstants.BASE_NAME}${RouteConstants.SCREENSHOTS}`,
        displayName: 'KPS Screenshots',
        keywords: [
          'kps',
          'get',
          'screenshot',
          'screen capture',
          'monitor',
          'live',
        ],
      },
      {
        route: `${RouteConstants.BASE_NAME}${RouteConstants.MONITORS}`,
        displayName: 'All KPS Workstations',
        keywords: [
          'all',
          'kps',
          'workstation',
          'monitor',
          '201',
          '202',
          '203',
          '204',
          '205',
          '206',
          '207',
          '208',
          '209',
          '210',
          '211',
          '212',
          '213',
          '214',
          '215',
          '216',
          '217',
          '218',
          '219',
          '220',
          '221',
          '222',
          '223',
          '224',
          '225',
          '226',
          '227',
          '228',
          '229',
          '230',
        ],
      },
      {
        route: `${RouteConstants.BASE_NAME}/workstations/200`,
        displayName: 'KPS Workstation Details',
        keywords: [
          'configure',
          'kps',
          'workstation',
          'detail',
          'monitor',
          'flip',
          'split view',
          'top',
          'bottom',
          'rename',
        ],
      },
      {
        route: `${RouteConstants.BASE_NAME}/workstations/200/settings`,
        displayName: 'Beep Settings',
        keywords: [
          'beep',
          'volume',
          'level',
          'alarm',
          'first',
          'transaction',
          'every',
        ],
      },
      {
        route: `${RouteConstants.BASE_NAME}/workstations/200/settings/bumpBar`,
        displayName: 'Bump Bar Settings',
        keywords: [
          'bump bar',
          'setting',
          'monitor',
          'workstation',
          'map',
          'key',
          'buttons',
          'screen',
          'window',
          'number',
          'first',
          'override',
          'auto bump',
          'time',
          'timer',
          'order ready',
          'push notification',
          'sos',
          'speed of service',
          'report',
          'cfa now',
          'estimated wait times',
          'master bump',
          'ignore',
          'real time',
          'data stream',
        ],
      },
      {
        route: `${RouteConstants.BASE_NAME}/workstations/200/settings/displayOptions`,
        displayName: 'KPS Workstation Settings',
        keywords: [
          'kps',
          'workstation',
          'settings',
          'general',
          'item',
          'order',
          'display',
          'window',
          'recall',
          'config',
          'fire',
          'counter',
          'bagging',
          'beverage',
          'break',
          'combo',
          'individual',
          'sub item',
          'breakout',
          'iPOS',
          'consolidate',
          'group',
          'repeat',
          'quantity',
          'base',
          'item group',
          'device group',
          'rearrange',
          'reorder',
          'drag',
          'service receipt',
        ],
      },
      {
        route: `${RouteConstants.BASE_NAME}${RouteConstants.ITEM_CONFIG}`,
        displayName: 'KPS Items',
        keywords: [
          'item',
          'item config',
          'rout',
          'reorder',
          'item group',
          'device',
          'device group',
        ],
      },
      {
        route: `${RouteConstants.BASE_NAME}${RouteConstants.ITEM_COLORS}`,
        displayName: 'Item Colors',
        keywords: ['item', 'color', 'preview', 'text', 'background', 'blink'],
      },
      {
        route: `${RouteConstants.BASE_NAME}${RouteConstants.ORDER_COLORS}`,
        displayName: 'KPS Order Colors',
        keywords: [
          'order',
          'color',
          'preview',
          'text',
          'background',
          'blink',
          'header',
          'window',
        ],
      },
      {
        route: `${RouteConstants.BASE_NAME}${RouteConstants.ALERTS}`,
        displayName: 'Transaction Alert Timers',
        keywords: [
          'transaction',
          'alert',
          'caution time',
          'timer',
          'secondary',
        ],
      },
      {
        route: `${RouteConstants.BASE_NAME}${RouteConstants.BACKUPS}`,
        displayName: 'KPS Backups',
        keywords: [
          'backup',
          'kps backup',
          'restore',
          'revert',
          'save',
          'version',
          'kps',
          'force',
          'snapshot',
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    if (permsLoaded) {
      userPermissions.next({
        ...userPermissions.value,
        hasKPSAccess: hasKpsPerms,
      });
      appLoadingStatuses.next({
        ...appLoadingStatuses.value,
        kpsLoaded: true,
      });
      globalRoutes.next({
        routes: [...globalRoutes.value.routes, ...routes],
      });
    }
  }, [hasKpsPerms, permsLoaded, routes]);

  useEffect(() => {
    const subscription = publishKpsCart.subscribe(result => {
      if (
        result.backupName.length &&
        result.cart.length &&
        selectedStoreNumber.length
      ) {
        spcApiService
          .syncChanges(
            selectedStoreNumber,
            createBackupPayload(result.cart),
            result.backupName,
            result.date ?? null,
          )
          .then(() => {
            if (result.date) {
              publishKpsCart.next({
                backupName: '',
                cart: [],
              });
              platformToast.next({
                toastInfo: {
                  content: 'KPS changes scheduled',
                  variant: 'success',
                },
              });
            } else {
              publishKpsCart.next({
                backupName: '',
                cart: [],
              });
              platformToast.next({
                toastInfo: {
                  content: 'KPS changes published',
                  variant: 'success',
                },
              });
            }
            getKpsCart(selectedStoreNumber);
            queryClient.resetQueries();
          });
      }
    });

    return () => subscription.unsubscribe();
  }, [queryClient, selectedStoreNumber]);

  useEffect(() => {
    deleteCartDataPoints.subscribe(cartGroups => {
      if (cartGroups.appName === AppNames.KPS && selectedStoreNumber.length) {
        spcApiService.deleteChanges(selectedStoreNumber).then(() => {
          deleteCartDataPoints.next({
            appName: null,
            dataPoints: [],
          });
          getKpsCart(selectedStoreNumber);
          queryClient.resetQueries();
        });
      }
    });
  }, [queryClient, selectedStoreNumber]);

  return (
    <Suspense fallback={null}>
      <AppRouter />
    </Suspense>
  );
}

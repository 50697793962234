enum RouteConstants {
  BASE_NAME = '/kps',
  HOME = '/',
  MONITORS = '/workstations',
  SCREENSHOTS = '/workstations/screenshots',

  ITEM_CONFIG = '/itemConfig',
  MULTIPLE_ITEM_COLORS_LIST = '/itemConfig/multiSelectColor',
  ITEM_CONFIG_DETAILS = '/itemConfig/:id',
  ITEM_CONFIG_ROUTING = '/itemConfig/:id/routing',

  GLOBAL_SETTINGS = '/globalSettings',
  ACTIVITY_LEVELS = '/globalSettings/activityLevels',
  ALERTS = '/alerts',
  ITEM_COLORS = '/itemConfig/itemColors',
  ROUTE_VALIDATION = '/itemConfig/routeValidation',
  MONITOR_VIEWS = '/workstations/:id',
  MONITOR_DETAILS = '/workstations/:id/settings',

  MONITOR_DETAILS_BUMP_BAR = '/workstations/:id/settings/bumpBar',

  MONITOR_DETAILS_DISPLAY_OPTIONS = '/workstations/:id/settings/displayOptions',

  VIEW_ROUTING = '/workstations/:id/routing/:viewPosition',

  VIEW_ROUTING_POS_ROUTING = '/workstations/:id/routing/:viewPosition/pos',

  VIEW_ROUTING_DESTINATION_ROUTING = '/workstations/:id/routing/:viewPosition/destination',
  VIEW_SETTINGS = '/workstations/:id/view/:viewPosition',
  VIEW_SETTINGS_RECEIPT_LABELS = '/workstations/:id/view/:viewPosition/receiptLabels',
  VIEW_SETTINGS_HEADER_FOOTER = '/workstations/:id/view/:viewPosition/headerFooter',

  ORDER_COLORS = '/orderColors',
  COLORS = '/colors',

  ITEM_COLORS_DETAILS = '/colors/itemColors/:id',
  MULTIPLE_ITEM_COLORS_DETAILS = '/colors/itemColors/multiple',
  BACKUPS = '/backups',
  CATEGORY_ITEM_COLORS_DETAILS = '/colors/itemColors/category/:id',
}

export default RouteConstants;

import { z } from 'zod';
import { itemDisplayTypeSchema } from './ItemDisplayType';
import { activityLevelRulesetSchema } from './ActivityLevelRuleset';

export const viewSchema = z.object({
  stationNumber: z.number(),
  storeNumber: z.string(),
  viewPosition: z.number(),
  rows: z.number(),
  columns: z.number(),
  viewName: z.string(),
  lastUpdatedAt: z.number(),
  title_bar_position: z.number(),
  word_wrap: z.number(),
  retain_combo_parent_child: z.number(),
  font_name: z.string(),
  frame_width: z.number(),
  caution_time: z.number(),
  alert_time: z.number(),
  display_time_type: z.number(),
  display_priority_items: z.number(),
  display_priority_trans: z.number(),
  displayCanceledTrans: z.boolean(),
  display_type_free_items: z.boolean(),
  canceled_items_displayed: z.boolean(),
  header_type_display: z.number(),
  footer_type_display: z.number(),
  suppress_condiment_pm: z.number(),
  display_refund_trans: z.number(),
  hide_all_condiments: z.number(),
  hide_combo_sub_items: z.number(),
  hide_piece_sub_items: z.number(),
  print_item_labels_before_first_bump: z.number(),
  primary_language_id: z.number(),
  secondary_language_id: z.number(),
  printer: z.string(),
  display_seat_number: z.number(),
  transaction_bump_on_status_override: z.number().optional(),
  view_option_bitmask: z.number(),
  twoDigitTransactionNumber: z.boolean(),
  itemSortOrder: z.number(),
  sortCondiments: z.boolean(),
  breakOutSeats: z.boolean(),
  bumpActivePrintingDisabled: z.number(),
  printItemLabelsSplsOnly: z.boolean(),
  printAutoCondOnAllTranReceipt: z.boolean(),
  printGuestPhoneNumberOnServiceReceipt: z.boolean(),
  print_receipt_on_bump: z.number(),
  printCustomerReceiptOnBump: z.number(),
  item_label_type: z.number(),
  item_display_type: z.number(),
  itemDisplayTypes: z.array(itemDisplayTypeSchema),
  hasItemDisplayOverrides: z.boolean().optional(),
  selectedPos: z.number().optional(),
  selectedItemDisplayType: z.number().optional(),
  doNotPrintForBumpActive: z.boolean(),
  acceptNothing: z.boolean(),
  showTraining: z.boolean(),
  loadBalance: z.boolean(),
  routeCondiments: z.boolean(),
  showWebOrder: z.boolean(),
  displayAllCondiments: z.boolean(),
  showTimer: z.boolean(),
  showEmployeeName: z.boolean(),
  showTransactionState: z.boolean(),
  showTransactionNumber: z.boolean(),
  showSalesDestination: z.boolean(),
  showPointOfSaleTerminal: z.boolean(),
  showTrainingLabel: z.boolean(),
  showCheckNumber: z.boolean(),
  showCustomerName: z.boolean(),
  showTentNumber: z.boolean(),
  showVehicleDescription: z.boolean(),
  enableDynamicHeaders: z.boolean(),
  showGuestIdentifier: z.boolean(),
  showRecoveryMessage: z.boolean(),
  showDeliveryZone: z.boolean(),
  showDeferredOrderPromiseDateTime: z.boolean(),
  showTableMarker: z.boolean(),
  showGuestCount: z.boolean(),
  showPager: z.boolean(),
  beverageHighlight: z.boolean(),
  showPrintTransactionTotalOption: z.number().optional(),
  showPrintTransactionTotalAtStoreTotal: z.boolean(),
  showPrintTransactionTotalAtTender: z.boolean(),
  showPrintDeferredOrderGuestInstructions: z.boolean(),
  showPrintMobileOrderGuestInstructions: z.boolean(),
  showSequenceNumber: z.boolean(),
  showCustomerPrepay: z.boolean(),
  activityLevelRulesets: z.array(activityLevelRulesetSchema),
  excludedDeviceGroups: z.number().array(),
  markedForPositionSwap: z.boolean().optional(),
  markedForNameChange: z.boolean().optional(),
  markedForDeletion: z.boolean().optional(),
  markedForCopy: z.boolean().optional(),
  dirty: z.boolean().optional(),
  compareToViewPosition: z.number().optional(),
  compareToStationNumber: z.number().optional(),
  copiedFrom: z.string().optional(),
  addedFrom: z.string().optional(),
  flipped: z.boolean().optional(),
  renamed: z.boolean().optional(),
});

type View = z.infer<typeof viewSchema>;

export default View;

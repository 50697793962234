import { z } from 'zod';

export const destinationSchema = z.object({
  destinationId: z.number(),
  destinationName: z.string(),
});

type Destination = z.infer<typeof destinationSchema>;

export default Destination;

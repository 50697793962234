import { z } from 'zod';

export const backupVersionsSchema = z.object({
  backupChangeEvents: z.string(),
  name: z.string(),
  version: z.string(),
  createdDate: z.date(),
  createdBy: z.string(),
});

type BackupVersions = z.infer<typeof backupVersionsSchema>;

export default BackupVersions;

import { z } from 'zod';

export const deviceGroupSchema = z.object({
  deviceGroupId: z.number(),
  deviceGroupName: z.string(),
  active: z.boolean(),
});

type DeviceGroup = z.infer<typeof deviceGroupSchema>;

export default DeviceGroup;

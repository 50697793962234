import { z } from 'zod';

export const deviceGroupOverrideSchema = z.object({
  retailItemId: z.number(),
  kdsRoutingNumberOverride: z.number(),
});

type DeviceGroupOverride = z.infer<typeof deviceGroupOverrideSchema>;

export default DeviceGroupOverride;

import { z } from 'zod';
import { stationSchema } from './Station';
import { viewSchema } from './View';
import {
  controlOptionSchema,
  terminalDevicesSchema,
  terminalFeatureSchema,
} from './Pos';

const kpsMonitorWithoutSnapshotSchema = stationSchema.extend({
  views: z.array(viewSchema),
  terminal_type: z.number(),
  consolidate: z.number(),
  color_scheme: z.number(),
  first_transaction_alarm: z.boolean(),
  master_bump_terminal: z.boolean(),
  screen_saver_filename: z.string(),
  flags: z.number(),
  ignore_master_bump: z.boolean(),
  use_auto_bump_override: z.boolean(),
  auto_bump_expire_time_override: z.number(),
  recoveryEnabled: z.boolean(),
  screenSaverEnabled: z.boolean(),
  beepsEnabled: z.boolean(),
  transactionalBeepType: z.number().optional(),
  speedOfServiceEnabled: z.boolean(),
  brandSeparationEnabled: z.boolean(),
  changeOrderEnabled: z.boolean(),
  bufferingEnabled: z.boolean(),
  productionEnabled: z.boolean(),
  bufferingSplitScreenEnabled: z.boolean(),
  verticalScrollingEnabled: z.boolean(),
  sendSosBumpToRtd: z.boolean(),
  sendMobileBumpToDxe: z.boolean(),
  mapKeysToScreenEnabled: z.boolean(),
  displayAllMobileOrderItems: z.boolean(),
  breakOutDesignatedSubitems: z.boolean(),
  displayAllIPosOrderItems: z.boolean(),
  kpsWindowThickBorder: z.boolean(),
  beepVolume: z.number(),
  bumpTemplateId: z.number(),
  displayMobileOrdersBasedOnKPSConfig: z.boolean(),
  lastUpdatedAt: z.number(),
  dirty: z.boolean().optional(),
  controlOptions: z.array(controlOptionSchema),
  features: z.array(terminalFeatureSchema),
  devices: terminalDevicesSchema,
  initialized: z.boolean(),
  added: z.boolean().optional(),
  addedTimeStamp: z.date().optional(),
  deleted: z.boolean().optional(),
  deletedTimeStamp: z.date().optional(),
  hardwareExists: z.boolean().optional(),
  imgLoading: z.boolean().optional(),
  imgUrl: z.string().optional(),
  imgError: z.boolean().optional(),
  imgErrorMessage: z.string().optional(),
  canRetryImg: z.boolean().optional(),
});

export const kpsMonitorSchema = kpsMonitorWithoutSnapshotSchema.extend({
  snapshotToCompare: kpsMonitorWithoutSnapshotSchema,
});

type KPSMonitor = z.infer<typeof kpsMonitorSchema>;

export default KPSMonitor;

import { z } from 'zod';
import { itemColorSchema } from './ItemColor';

const retailModifiedItemSchema = z.object({
  retailModifiedItemId: z.number(),
  name: z.string(),
  modifier: z.string(),
  description: z.string(),
  localItem: z.number(),
  amount: z.number(),
  parentDeviceGroupId: z.number().optional(),
  parentDeviceGroupName: z.string().optional(),
  dirty: z.boolean().optional(),
  itemColor: itemColorSchema.optional(),
});

export type RetailModifiedItem = z.infer<typeof retailModifiedItemSchema>;

export const itemSchema = z.object({
  itemId: z.number(),
  deviceGroupId: z.number(),
  deviceGroupName: z.string().optional(),
  name: z.string(),
  description: z.string(),
  imageUrl: z.string().optional(),
  priority: z.number().optional(),
  items: z.array(retailModifiedItemSchema),
  dirty: z.boolean().optional(),
});

type Item = z.infer<typeof itemSchema>;

export default Item;

import { z } from 'zod';

const bumpTemplateButtonSchema = z.object({
  action: z.number(),
  bumpTemplateId: z.number(),
  buttonNumber: z.number(),
  compareToValue: z.number(),
  parameter: z.number(),
});

export const bumpTemplateSchema = z.object({
  bumpTemplateId: z.number(),
  bumpTemplateName: z.string(),
  buttons: z.array(bumpTemplateButtonSchema),
});

type BumpTemplate = z.infer<typeof bumpTemplateSchema>;

export default BumpTemplate;

import { z } from 'zod';
import { rulesetSchema } from './Ruleset';

export const activityLevelRulesetSchema = z.object({
  activityLevelId: z.number(),
  ruleset: rulesetSchema,
});

type ActivityLevelRuleset = z.infer<typeof activityLevelRulesetSchema>;

export default ActivityLevelRuleset;

import { z } from 'zod';

export const controlOverrideDeviceSchema = z.object({
  terminalNumber: z.number(),
  controlNumber: z.number(),
  controlOptionValue: z.number(),
});

type ControlOverrideDevice = z.infer<typeof controlOverrideDeviceSchema>;

export default ControlOverrideDevice;

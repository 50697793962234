import { z } from 'zod';

export const transactionAlertSchema = z.object({
  destinationId: z.number(),
  destinationName: z.string(),
  displayAuxTimer: z.boolean(),
  alertTime: z.number(),
  alertTimeMinutes: z.number().optional(),
  cautionTime: z.number(),
  cautionTimeMinutes: z.number().optional(),
  startTimerFromStateOverride: z.number().optional(),
  lastUpdatedAt: z.number(),
  errorMessage: z.string().optional(),
  dirty: z.boolean().optional(),
});

type TransactionAlert = z.infer<typeof transactionAlertSchema>;

export default TransactionAlert;

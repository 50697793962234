import { z } from 'zod';

export const destinationDisplayTimeSchema = z.object({
  destinationId: z.number(),
  startTimerFromState: z.number(),
  lastUpdatedAt: z.number().optional(),
  dirty: z.boolean().optional(),
});

type DestinationDisplayTime = z.infer<typeof destinationDisplayTimeSchema>;

export default DestinationDisplayTime;

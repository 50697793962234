import { z } from 'zod';

export const colorDetailSchema = z.object({
  backColorId: z.number(),
  blinking: z.boolean(),
  configurable: z.boolean(),
  foreColorId: z.number(),
  transparent: z.boolean(),
  enableObjectColorOverride: z.boolean(),
  lastUpdatedAt: z.number(),
  lastUpdatedBy: z.union([z.string(), z.null()]),
  skipModifiedDateCheck: z.boolean(),
  skipExistCheck: z.boolean(),
  colorDetailId: z.number(),
  description: z.string(),
  compareToValue: z.number(),
});

type ColorDetail = z.infer<typeof colorDetailSchema>;

export default ColorDetail;

import { z } from 'zod';

export const controlOptionSchema = z.object({
  controlNumber: z.number(),
  controlOptionValue: z.number(),
});

export type ControlOption = z.infer<typeof controlOptionSchema>;

export const terminalFeatureSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export type TerminalFeature = z.infer<typeof terminalFeatureSchema>;

export const devicePrinterSchema = z.object({
  name: z.string(),
  model: z.number(),
  adapter: z.number(),
});

export type DevicePrinter = z.infer<typeof devicePrinterSchema>;

export const deviceBumpBarSchema = z.object({
  type: z.string(),
  model: z.number(),
  adapter: z.number(),
  port: z.number(),
  bumpTemplateId: z.number(),
});

export type DeviceBumpBar = z.infer<typeof deviceBumpBarSchema>;

export const remotePrinterSettingsSchema = z.object({
  terminals: z.number().array(),
  salesDestinations: z.number().array(),
  deviceGroups: z.number().array(),
});

export type RemotePrinterSettings = z.infer<typeof remotePrinterSettingsSchema>;

export const remotePrinterSchema = z.object({
  model: z.number(),
  settings: remotePrinterSettingsSchema,
});

export type RemotePrinter = z.infer<typeof remotePrinterSchema>;

export const terminalDevicesSchema = z.object({
  deviceMsr: z.number(),
  devicePrinter: devicePrinterSchema,
  deviceBumpBars: z.array(deviceBumpBarSchema),
  deviceCustomerDisplay: z.number().array(),
  deviceCashDrawer: z.number(),
  deviceCheckReader: z.number().array(),
  deviceChangeMachine: z.number(),
  devicePinPad: z.number(),
  deviceScanner: z.number().array(),
  remotePrinters: z.array(remotePrinterSchema),
});

export type TerminalDevices = z.infer<typeof terminalDevicesSchema>;

export const posWithoutSnapshotSchema = z.object({
  nodeNumber: z.number(),
  nodeName: z.string(),
  stationType: z.number(),
  virtualReceiptType: z.number(),
  displayTotalType: z.number(),
  displayTaxType: z.number(),
  localReportPrinter: z.string(),
  receiptPrinterType: z.number().optional(),
  printerFromPOS: z.number(),
  paymentTerminalId: z.number(),
  defaultDestinationId: z.number(),
  spsmEnabled: z.boolean(),
  receiptPrinterEnabled: z.boolean(),
  largeFontEnabled: z.boolean(),
  requireDestinationBeforeOrder: z.boolean(),
  requireDestinationForDeferredOrderBeforeOrder: z.boolean().optional(),
  printReportAtEndOfShift: z.boolean(),
  printPaidInPaidOutEnabled: z.boolean(),
  printTimeClockReceipts: z.boolean(),
  changeMachineEnabled: z.boolean(),
  active: z.boolean(),
  useTableMarkers: z.boolean().optional(),
  tableMarkerColor: z.number().optional(),
  tipReceiptCopyAmount: z.number(),
  doNotPrintAfterTender: z.boolean(),
  creditProcessorType: z.number(),
  terminalControlOptions: z.array(controlOptionSchema),
  terminalFeatures: z.array(terminalFeatureSchema),
  terminalDevices: terminalDevicesSchema,
  initialized: z.boolean(),
  orderTaker: z.boolean().optional(),
  moneyTaker: z.boolean().optional(),
  added: z.boolean().optional(),
  addedTimeStamp: z.date().optional(),
  deleted: z.boolean().optional(),
  deletedTimeStamp: z.date().optional(),
  hardwareExists: z.boolean().optional(),
  imgLoading: z.boolean().optional(),
  imgUrl: z.string().optional(),
  imgError: z.boolean().optional(),
  imgErrorMessage: z.string().optional(),
  canRetryImg: z.boolean().optional(),
  isRegister: z.function().returns(z.boolean()),
});

export const posSchema = posWithoutSnapshotSchema.extend({
  snapshotToCompare: posWithoutSnapshotSchema,
});

type Pos = z.infer<typeof posSchema>;

export default Pos;

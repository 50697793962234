import { z } from 'zod';

export const rulesetSchema = z.object({
  rulesetId: z.number(),
  destinations: z.number().array(),
  stations: z.number().array(),
  itemsAcceptedModified: z.number().array(),
  itemsAcceptedNever: z.number().array(),
  dirty: z.boolean().optional(),
});

type Ruleset = z.infer<typeof rulesetSchema>;

export default Ruleset;

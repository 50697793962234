import { z } from 'zod';

export const itemColorSchema = z.object({
  lastUpdatedBy: z.string().optional(),
  lastUpdatedAt: z.number().optional(),
  skipModifiedDateCheck: z.boolean().optional(),
  skipExistCheck: z.boolean().optional(),
  retailModifiedItemId: z.number(),
  foreColorId: z.number(),
  backColorId: z.number(),
  blinking: z.boolean(),
  foreColorIdForSubst: z.number().optional(),
  backColorIdForSubst: z.number().optional(),
  blinkingForSubst: z.boolean().optional(),
  overriden_flag: z.number().optional(),
  compareToValue: z.number().optional(),
  defaulted: z.boolean().optional(),
  dirty: z.boolean().optional(),
});
type ItemColor = z.infer<typeof itemColorSchema>;

export default ItemColor;

import { z } from 'zod';

export const deviceGroupPrioritySchema = z.object({
  deviceGroupId: z.number(),
  stationNumber: z.number(),
  priority: z.number(),
  deviceGroupName: z.string(),
  hasItems: z.union([z.boolean(), z.null()]),
});

type DeviceGroupPriority = z.infer<typeof deviceGroupPrioritySchema>;

export default DeviceGroupPriority;

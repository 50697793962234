import { z } from 'zod';
import { colorDetailSchema } from './ColorDetail';
import { transactionColorDetailSchema } from './TransactionColorDetail';

export const transactionColorsSchema = z.object({
  regularRetailItem: colorDetailSchema,
  selectedWindow: colorDetailSchema,
  guestInstructions: colorDetailSchema,
  sequence: colorDetailSchema,
  lowestSequence: colorDetailSchema,
  modifiedQuantities: colorDetailSchema,
  cancelledItem: colorDetailSchema,
  freeItem: colorDetailSchema,
  recoveryMessage: colorDetailSchema,
  preTimerDetails: transactionColorDetailSchema,
  initialDetails: transactionColorDetailSchema,
  cautionDetails: transactionColorDetailSchema,
  alertDetails: transactionColorDetailSchema,
  windowBorderThick: z.boolean(),
  lastUpdatedAt: z.union([z.number(), z.string()]),
  lastUpdatedBy: z.string(),
  skipModifiedDateCheck: z.boolean(),
  skipExistCheck: z.boolean(),
  dirty: z.boolean().optional(),
});

type TransactionColors = z.infer<typeof transactionColorsSchema>;

export default TransactionColors;

/* eslint "sort-keys": ["warn", "asc", {"caseSensitive": false, "natural": false, "minKeys": 2}] */
export default {
  AppRoutes: {
    globalSettings: 'Global Settings',
    itemColors: 'Item Colors',
    itemConfig: 'Item Config',
    kpsMonitorDetails: 'kpsMonitor/:id',
    kpsMonitors: 'KPS Workstations',
    orderColors: 'Order Colors',
    transactionAlerts: 'Transaction Alerts',
  },
  Backups: {
    backupChanges: '{{settingName}} - {{changes}}',
    backupCreatedName: 'by {{name}}',
    backupDate: '{{monthAndYear}} at {{hourAndMinutes}}',
    backupRange:
      '{{startingBackupNumber}} - {{endingBackupNumber}} of {{totalBackups}}',
    backupRestoredModalTitle: 'Backup has been added to the cart',
    cancel: 'Cancel',
    confirm: 'Confirm',
    dateLabel: 'Date',
    forceSendButton: 'Force-send only these changes',
    forceSendHelpText:
      'Sends only updates contained in this backup to the store. Other backups and changes remain intact.',
    forceSendModalInfo:
      'Updates contained in this backup will be sent to the store. This cannot be undone. Other backups and their changes will remain intact.',
    forceSendModalSecondLineInfo: 'Note about the force/priority aspect?...',
    forceSendModalTitle: 'Force-send only these changes',
    forceSendModalWarningText:
      'Store server (KPS) and POS reboots are required',
    header: 'KPS Backups',
    next: 'NEXT',
    notificationFirstLine:
      'A backup is scheduled to send on {{date}} at {{time}}',
    notificationSecondLine:
      'Any new KPS updates will need to be bundled with this backup',
    prev: 'PREV',
    restoreBackButtonText: 'Restore backup',
    restoreBackupModalBody:
      'Your store’s KPS settings will be reset to the settings on {{date}} at {{time}}. This cannot be undone.',
    restoreBackupModalTitle: 'Restore this backup',
    restoreBackupModalWarning:
      '{{index}} backups and the changes contained in them will be erased',
    restoreBackupModalWarningForOne:
      '1 backup and the changes contained in it will be erased',
    restoreButton: 'Restore this backup',
    restoreHelpText:
      'Reverts KPS settings to the date and time of this backup. All KPS backups made after this date/time will be erased.',
    saveBackupToCartText: 'Keep in cart',
    saveToCartBoldHelpText:
      'All additional KPS updates must be sent along with this backup.',
    saveToCartHelpText: 'Backup can be scheduled for a later date/time. ',
    scheduleDatePlaceholder: 'mm/dd/yyyy',
    scheduleLaterText: 'Schedule for later',
    scheduleLaterTitle: 'Schedule a force-send',
    scheduleNowText: 'Send now',
    sendToStoreNowText: 'Send to store now',
    subtitle: 'View recent KPS updates/versions and restore backups',
    TimeLabel: 'Time',
  },
  CategoryItemColorsDetails: {
    backgroundColor: 'Background Color',
    header: 'Multiple Item Colors',
    resetToDefault: 'Reset to Default',
    textColor: 'Text Color',
  },
  Colors: {
    header: 'Colors',
  },
  DestinationRouting: {
    description: 'Choose which Order Types should show on this view',
  },
  DisplayOptions: {
    close: 'Cancel',
    dragAndDropToReorder: 'Drag to reorder',
    reorder: 'Reorder',
    save: 'Add to Cart',
  },
  ErrorPage: {
    buttonText: 'Go to Home Screen',
    errorDescription: 'Please try again or contact the helpdesk',
    errorTitle: 'Something went wrong',
    maintenanceTitle: 'ControlPoint is currently down for maintenance',
  },
  GlobalSettings: {
    activityLevelDuplicateButton: 'Duplicate',
    activityLevelEditButton: 'Edit',
    activityLevelModalCancelButton: 'Cancel',
    activityLevelModalDeleteButton: 'Delete',
    activityLevelModalDuplicateButton: 'Duplicate',
    activityLevelModalUpdateButton: 'Update',
    activityLevelNameLabel: 'Activity level name',
    activityLevelsSectionLabel: 'Activity Levels',
    activityLevelsSubtitle:
      'Create activity levels that reflect how busy your restaurant is. The routing options for any KPS can be customized by activity level.',
    allTransactionServiceReceiptsLabel: 'Transaction Service Receipts',
    autoBumpTimeHelpText:
      'Set the Auto Bump Expire Time. If an order is able to be bumped and has been in this status over the time set in this field, the order is auto-bumped. This setting can be overridden by KPS stations in KPS Settings.',
    autoBumpTimeLabel: 'Auto-bump time (minutes)',
    autoBumpTimePlaceholder: '1 minute',
    bumpBarSectionLabel: 'Bump Bar',
    deleteModalCancelButton: 'Cancel',
    deleteModalConfirmButton: 'Delete',
    deleteModalSubtitle:
      'All routing configurations associated with "{{activityLevelName}}" will be deleted.',
    deleteModalTitle: 'Delete activity level?',
    duplicateActivityLevelModalTitle: 'Duplicate activity level',
    editActivityLevelModalTitle: 'Edit activity level',
    enableSequencingLabel: 'Enable sequencing on tender for Mobile-thru orders',
    enableSequencingSubLabel:
      'When turned on, Team Members can sequence prepaid mobile orders from Drive-thru SPFlex Terminals.',
    itemFontSizeHelpText: 'Choose a font size',
    itemFontSizeLabel: 'Item font size',
    itemFontSizePlaceholder: 'Small Font',
    largeFontText: 'Large',
    menuItemLargeFontLabel: 'Use large font on menu items',
    OrderSequencingSectionLabel: 'Order Sequencing',
    printerSectionLabel: 'Printer',
    routedItemsReceipts: 'Routed items receipts',
    smallFontText: 'Small',
    specialItemLabelsLabel: 'Special item labels',
    startTimerFromStateHelpText:
      'This determines when timers on orders should start. The most common selection is “Active.” This can be overridden by Order Type in Transaction Alerts.',
    startTimerFromStateLabel: 'Start timer from state',
    startTimerFromStatePlaceholder: 'Select a status',
    subItemFontSizeHelpText: 'Choose a font size',
    subItemFontSizeLabel: 'Sub-item font size',
    subItemFontSizePlaceholder: 'Small Font',
    title: 'Global Settings',
    transactionBumpOnStatusHelpText:
      'The state selected determines which state a transaction must reach before Team Members are able to bump the transaction. This setting can be overridden by KPS Views if another state  is selected in KPS View Settings.',
    transactionBumpOnStatusLabel: 'Transaction bump on status',
    transactionBumpOnStatusPlaceholder: 'Select a status',
  },
  Home: {
    title: 'KPS',
  },
  ItemColors: {
    colorButtonText: 'Color {{number}} Selected Items',
    description: 'Select items to change color',
    editSelectedItems: 'Edit Selected Items',
    header: 'Item Colors',
    selectAll: 'Select all',
    viewAndEdit: 'Select an item to view and edit its colors',
  },
  ItemColorsDetails: {
    activeStatusPlaceholder: 'Active R52',
    all: 'All',
    backgroundColor: 'Background color',
    blinkerText: 'Blink item',
    destinationPlaceholder: 'Destination',
    guestNamePlaceholder: 'CHRIS',
    number: '#2893',
    resetToDefault: 'Reset to Default',
    textColor: 'Text color',
    timerPlaceholder: '3:42',
    transactionDetailColorsHeader: 'Preview',
  },
  ItemConfig: {
    allItemsDeviceGroupOption: 'All Items',
    emptyItems: "We couldn't find any items",
    header: 'Items',
    monitorCopyModalBackButton: 'Go back to Reorder',
    monitorCopyModalHeader:
      'Choose additional KPSs to copy this order to (optional)',
    monitorCopyModalKpsLabel: 'KPS',
    monitorCopyModalSubmitButton: 'Add to Cart',
    monitorSelectModalHeader: 'Choose a KPS to start',
    monitorSelectModalKpsLabel: 'KPS',
    monitorSelectModalSubmitButton: 'Continue',
    reorderButton: 'Reorder this Item Group',
    reorderItemsModalBottomButton: 'Bottom',
    reorderItemsModalCancelButton: 'Cancel',
    reorderItemsModalHeader: 'Reorder {{deviceGroup}} in {{monitor}}',
    reorderItemsModalSubeader:
      'Drag to reorder items, or use the "Top" and "Bottom" buttons to move items to the very top or bottom of the list.',
    reorderItemsModalSubmitButton: 'Continue',
    reorderItemsModalTopButton: 'Top',
    searchPlaceholder: 'Search item name or keyword',
  },
  ItemConfigDetails: {
    categoryLabel: 'Item Group (Device Group)',
    changeColorsButton: 'Change colors',
    colorsLabel: 'Color(s)',
    colorsSublabel: 'Preview text',
    emptyItem: 'No item found',
  },
  ItemConfigRouting: {
    activityLevelLabel: 'Activity Level',
    copyRouteButton: "Copy this item's route to another item",
    copyRouteModalCancelButton: 'Cancel',
    copyRouteModalHeader: 'Copy {{name}} routes to:',
    copyRouteModalItemsSelected: 'items selected',
    copyRouteModalSubmitButton: 'Copy item routes',
    copyRouteSearchPlaceholder: 'Search item',
    destinationLabel: 'Order Type',
    editRouteButton: 'Edit Routing',
    header: '{{name}} Routing',
    kpsLabel: 'KPS',
    kpsTableHeader: 'KPS & Activity Level',
    next: 'Next',
    posLabel: 'POS',
    selectSizeModalHeader: 'Select size to copy',
    tableEmptyText: 'No items to show',
    visibilityTableHeader: 'Visibility',
  },
  KPSMonitorDetails: {
    adminOnly: 'Admin-only',
    beepOnEveryTransaction: 'Beep on every order',
    beepOnEveryTransactionCaption:
      'Makes a short beep for every new order added to the screen',
    beepOnFirstTransaction: 'Beep on first order',
    beepOnFirstTransactionCaption:
      'Makes a loud, lengthy beep when a new order appears on a previously empty screen.',
    beepSettings: 'Beep Settings',
    beepVolume: 'Beep Volume',
    beepVolumeCaption: 'Controls all beep volume levels',
    bumpBarTemplate: 'Bump bar template',
    bumpBarTemplateCaption: '(not editable)',
    categoriesDeviceGroups: 'Item Groups (Device Groups)',
    categoriesDeviceGroupsOrder:
      'Item Groups (Device Groups) appear on this KPS and its service receipts in this order:',
    consolidateItems: 'Group repeated items',
    consolidateItemsCaption:
      'Batches together repeated items in an order and shows their total quantity. For example: “',
    consolidateItemsCaption2: '2  CFA Sand',
    consolidateItemsCaption3:
      '" Choose “None” to list each item separately. For example,',
    consolidateItemsCaption4: '1  CFA Sand',
    customAutoBumpTime: 'Custom Auto-Bump Time (minutes)',
    general: 'General',
    ignoreMasterBump: 'Ignore the Master Bump from other terminals',
    ignoreMasterBumpCaption:
      'A Master Bump from another terminal will not affect this KPS',
    itemsAndOrders: 'Items & Orders',
    mapKeysToScreenEnabled: 'Match bump bar numbers with screen grid',
    mapKeysToScreenEnabledCaption:
      'Makes the arrangement of numbered buttons on the bump bar match the rows and columns on the screen. For example, if the first order on the screen is large and takes up two spaces, pressing the “2” button on the bump bar will bump that order.',
    mapKeysToScreenEnabledCaption2:
      'Turning Off this setting makes pressing the “1” button bump the first order, the “2” button bump the second, etc.',
    masterBump: 'Master Bump',
    masterBumpCaption:
      'An order bumped on this KPS will bump that order on all other KPS terminals (unless a terminal is set to ignore the Master Bump)',
    notificationArrivalButtonText: 'Go to Digital Ordering',
    notificationArrivalLabel: 'Notify KPS upon driver arrival',
    notificationArrivalSubLabel:
      'When turned on, a notification is sent to the KPS to inform Team Members that the 3rd Party Delivery driver has arrived to pick up the order.',
    overrideAutoBumpTime: 'Override Auto-Bump Time',
    overrideAutoBumpTimeCaption:
      'Turn On to set a custom Auto-Bump time for this terminal. The default Auto-Bump Time is set to 30 min in Global Settings',
    reporting: 'Reporting',
    sendBumpInfo: 'Send bump info to real-time data streams',
    sendBumpInfoCaption:
      'This setting is automatically enabled if Master Bump is turned on',
    sendMobileGuestsOrderReadyNotifications:
      'Bump sends mobile guests an “Order Ready” notification and SoS reporting data',
    sendMobileGuestsOrderReadyNotificationsCaptionFirstBullet:
      'Mobile guests receive a push notification and see order status updates when this KPS is bumped. (If this is turned On for multiple KPSs, guests are notified on the first bump.)',
    sendMobileGuestsOrderReadyNotificationsCaptionFirstTitle:
      'When this KPS is bumped the following happens:',
    sendMobileGuestsOrderReadyNotificationsCaptionNote: 'Note',
    sendMobileGuestsOrderReadyNotificationsCaptionNote1:
      ' Only turn On for the last KPS to complete the order fulfillment process. For example, a Drive-thru KPS by the window/door, a Front Counter Bagging KPS, or other KPS that serves as your final bump.',
    sendMobileGuestsOrderReadyNotificationsCaptionNote2:
      ' This control is automatically turned On if Master Bump (Admin-only setting) is enabled.',
    sendMobileGuestsOrderReadyNotificationsCaptionSecondBullet:
      'Triggers an order-ready signal to all delivery services',
    sendMobileGuestsOrderReadyNotificationsCaptionSecondCaption:
      'Turning On this setting will include this KPS in Speed of Service reporting. reports to CFA Now. (if multiple KPSs have this control turned on, the SOS uses the last KPS bumped)',
    sendMobileGuestsOrderReadyNotificationsCaptionSecondTitle:
      'Speed of Service',
    sendMobileGuestsOrderReadyNotificationsCaptionThirdBullet:
      'Estimated wait times are calculated',
    showAllItems: 'Show all items in Mobile orders',
    showAllItemsCaption:
      'Use only on counter bagging workstations. Shows all items in Mobile orders, including beverages. Beverages will not display on these screens otherwise.',
    showAllItemsIPOS: 'Show all items in iPOS orders',
    showAllItemsIPOSCaption:
      'Use only on counter bagging workstations. Shows all items in iPad and register POS orders, including beverages. Beverages will not display on these screens otherwise.',
    showChangeOrder: 'Show "CHANGE ORDER"',
    showChangeOrderCaption: 'Show “',
    showChangeOrderCaption2: 'CHANGE ORDER',
    showChangeOrderCaption3:
      '” at the top of an order if it changes after being recalled',
    showMobileOrders: 'Show Mobile orders based on KPS configuration',
    showMobileOrdersCaption:
      'Controls if mobile orders will show based on KPS settings rather than automatically displaying when fired.',
    showSubItemBreakdown: 'Break combos into individual items',
    showSubItemBreakdownCaption:
      'Items that are part of combos are listed individually and not labeled as combos',
    speedOfService: 'Speed of Service',
    speedOfServiceCaption:
      'Includes this KPS in speed of service reporting. This is automatically turned On if Master Bump is enabled (admin setting).',
    subtitle:
      'All settings apply to both {{topView}} (Top) and {{bottomView}} (Bottom)',
    title: 'KPS {{number}} Workstation Settings',
  },
  KPSMonitors: {
    addView: 'ADD VIEW',
    bottomViewLabel: '(Bottom)',
    flipViews: 'FLIP VIEWS',
    monitorSettings: '{{stationName}} Settings',
    monitorViewModalAddButton: 'Add view',
    monitorViewModalAddTitle: 'Add a view to {{viewName}}',
    monitorViewModalCancelButton: 'Cancel',
    monitorViewModalEditTitle: 'Edit',
    monitorViewModalNameLabel: 'Name',
    monitorViewModalPositionLabel: 'Position on screen',
    monitorViewModalRemoveButton: 'Remove view',
    monitorViewModalSwitchButton: 'Switch',
    monitorViewModalUpdateButton: 'Update',
    monitorViewModalViewLabel: 'KPS view to copy',
    popoverAddLabel: 'Add another view',
    popoverEditLabel: 'Edit {{viewName}}',
    popoverSwitchLabel: 'Switch Top and Bottom',
    removePromptCancelButton: 'Cancel',
    removePromptSubmitButton: 'Remove',
    removePromptTitle: 'Remove "{{viewName}}" from {{stationName}}',
    removeView: 'REMOVE VIEW',
    routing: 'Routing',
    switchPromptCancelButton: 'Cancel',
    switchPromptSubmitButton: 'Switch',
    switchPromptSubtitle: 'Switching views will',
    switchPromptSubtitleItem:
      '- Move "{{viewName}}" to the {{viewPosition}} of the KPS',
    switchPromptTitle: 'Switch {{stationName}} top and bottom views',
    title: 'KPS Workstations',
    topViewLabel: '(Top)',
    viewPositionBottom: 'Bottom',
    viewPositionTop: 'Top',
    viewSettings: 'Settings',
  },
  KPSMonitorViews: {
    addViewText: 'Add another view',
    appearText: 'appear on the screen, ',
    beepText: 'beep settings',
    bumpText: 'bump bar settings, ',
    editViewText: 'Edit view',
    how: 'How ',
    itemsAndOrders: 'items and orders ',
    seeScreenShotText: 'See screenshots',
    settingTitle: 'KPS Settings',
    title: 'KPS {{number}}',
    topViewDesFirstText: 'Choose which ',
    topViewDesFiveText: 'and which ',
    topViewDesFourText: 'Order Type ',
    topViewDesSecondText: 'items ',
    topViewDesSevenText: 'orders come from.',
    topViewDesSixText: 'POS ',
    topViewDesThirdText: 'appear. Customize by ',
    topViewSettingDesFirstText: 'Customize ',
    topViewSettingDesFiveText: 'receipts and labels',
    topViewSettingDesFourText: 'and ',
    topViewSettingDesSecondText: 'order display options, ',
    topViewSettingDesThirdText: 'information in orders, ',
    topViewSettingTitle: '{{name}} Settings',
    topViewTitle: 'Item & Order Routing',
    workstation: 'Workstation',
  },
  KPSViewSettings: {
    addOverride: 'Add override',
    assistsWithTheftProtection: 'Assists with theft protection',
    breakOutOrdersBySeat: 'Break out orders by seat',
    breakOutOrdersBySeatCaption:
      'Separates orders by seat number. If turned Off, orders containing seats are divided within one order window on the screen.',
    close: 'Cancel',
    createAnOverride: 'Override when to show orders',
    createOverride: 'Create override',
    customerReceipts: 'Customer Receipts',
    display: 'Display',
    displayCancelledItems: 'Show Cancelled items',
    displayCancelledTransactions: 'Show Cancelled orders',
    doNotPrintForBumpActiveCaption:
      'Enable if a receipt should not print when Bumped Active is pressed to bump an order.',
    doNotPrintForBumpActiveTitle: 'Do not Print for Bump Active',
    editTerminals: 'Edit',
    enableDynamicHeaders: 'Allow flexible headers',
    enableDynamicHeadersCaption:
      'The header area of an order grows/shrinks based on the amount of information it contains. Turning this setting Off makes the header a standard height and may waste space on the screen.',
    footer: 'Footer',
    general: 'General',
    header: 'Header',
    highlightBeveragesTitle: 'Highlight all beverages and cold treats',
    includeAutoAddedCondimentsTitle: 'Include auto-added condiments',
    includeGuestsPhoneNumberCaption:
      'Disable if your restaurant does not use the guest phone number to call mobile guests',
    includeGuestsPhoneNumberTitle:
      "Include guest's phone number on Service Receipts",
    itemDisplay: 'Item display',
    itemDisplayCaption: 'Change how and when items display on this KPS View.',
    labelStyle: 'Label Style',
    labelStyleCaption: 'Label format to print from the specials printer',
    not: 'not',
    note: 'Note',
    noTerminalsSelected: 'Select a Shared POS',
    numberOfColumns: 'Number of columns',
    numberOfRows: 'Number of rows',
    numberOnVirtualReceipt: 'The number on the virtual receipt',
    onlyPrintItemLabelsForSpecials: 'Only print item labels for specials',
    orders: 'Orders',
    overrideOrderStatus: 'Stage in transaction',
    overrideRegister: 'POS',
    printCustomerReceiptBulletPoint1:
      'Customer Receipt will only print for orders tendered on SP Flex (including mobile orders that are recalled and tendered on SP Flex).',
    printCustomerReceiptBulletPoint2:
      'If multiple KPS are set to print the Customer Receipt, it will only print once on the first KPS that bumps the order.',
    printCustomerReceiptBulletPoint3:
      'The Customer Receipt only prints once per order. Recalling an order and bumping it again will not cause the Customer Receipt to print again.',
    printCustomerReceiptBulletPoint4:
      'If both a Service Receipt and Customer Receipt are set to print on the same KPS, the Service Receipt will print first.',
    printCustomerReceiptBulletPoint5:
      'For an ideal customer experience, the Customer Receipt should not be printed on sticky paper.',
    printCustomerReceiptNote1: ': Please submit a ',
    printCustomerReceiptNote2: 'KPS Change Request',
    printCustomerReceiptNote3:
      ' on Service Gateway to configure the printer for this feature. If bumping from a Window or Door KPS in the Drive Thru, the HELP desk will have to configure this feature to print customer receipts on the Window or Door POS Receipt Printer.',
    printCustomerReceiptTitle: 'Print Customer Receipt On KPS Bump',
    printCustomerReceiptTitleCaption1:
      'Customer Receipt will only print for orders tendered on iPad POSs (including mobile orders that are recalled and tendered on iPad POSs)',
    printCustomerReceiptTitleCaption3Part1:
      'The Customer Receipt only prints once per order. Recalling an order and bumping it again will ',
    printCustomerReceiptTitleCaption3Part2:
      ' cause the Customer Receipt to print again',
    printCustomerReceiptTitleCaption4:
      'If both a Service Receipt and Customer Receipt are set to print on the same KPS, the Service Receipt will print first',
    printCustomerReceiptTitleCaption5:
      'For the best guest experience, the Customer Receipt should not be printed on sticky paper',
    printer: 'Printer',
    printOnBump: 'Print on Bump',
    printOnBumpCaption:
      'Type of receipt or label printed when this KPS is bumped',
    receiptPaperSizeCaption:
      'This 58 millimeter paper is narrower than the typical paper width',
    receiptPaperSizeTitle: 'Receipt paper size',
    remove: 'Remove',
    removeOverrideForPos: 'Remove override for {{posName}}?',
    selectedTerminals: 'Shared POSs',
    selectTerminals: 'Select',
    separateFreeItems: 'Separate free items',
    separateFreeItemsCaption:
      'When turned on, free items are separated from non-free items and appear with their free item',
    separateFreeItemsCaption2: ' custom colors.',
    serviceReceiptsSpecialLabels: 'Service Receipts & Special Labels',
    sharedSpFlexTerminals: 'Shared iPad POSs',
    sharedSpFlexTerminalsCaption:
      ': Changing here can affect other KPSs. All KPSs that have "Print Customer Receipt" turned On are linked to these devices',
    showCustomerName: 'Show customer name',
    showCustomerPrepay: 'Show Customer Prepay',
    showCustomerPrepayCaption:
      'Displays the customer prepay indication for POS orders that have customer prepay',
    showDeferredOrderPromiseDate: 'Show Deferred order promise time/date',
    showDeferredOrderPromiseDateCaption:
      'Orders show the promise date/time for Deferred orders',
    showEmployeeName: 'Show employee name',
    showEmployeeNameCaption: 'Shows the name of the cashier taking the order',
    showGuestCount: 'Show number of guests',
    showGuestCountCaption:
      'The KPS shows the guest count associated with the order. Comes from Deferred orders.',
    showGuestIdentifier: 'Show guest identifier',
    showGuestIdentifierCaption: 'Orders show the guest identifier',

    showMobileDeliveryZone: 'Show mobile delivery zone',
    showMobileDeliveryZoneCaption:
      'Orders show additional information (i.e. table numbers, parking lot zones, etc.) for finding mobile order guests',
    showPointOfSaleTerminal: 'Show POS where order was taken',
    showPrintDeferredOrderGuestInstructions:
      'Show/Print Deferred Order Guest Instructions',
    showPrintDeferredOrderGuestInstructionsCaption:
      'This option displays the guest instructions from a deferred order on the KPS.',
    showPrintMobileOrderGuestInstructions:
      'Show/Print Mobile Order Guest Instructions',
    showPrintMobileOrderGuestInstructionsCaption:
      'This options displays the guest instructions from a mobile order on the KPS.',
    showPrintTransactionTotalAtStoreTotal:
      'Show/Print when order is Stored and Totaled',
    showPrintWhenOrderTendered: 'Show/Print when order is Tendered',
    showRecoveryMessage: 'Show recovery message',
    showRecoveryMessageCaption:
      'Determines if recovery DOC messages will display.',
    showSalesDestination: 'Show Order Type',
    showSequenceNumber: 'Show Sequence Number',
    showSequenceNumberCaption:
      'Displays the sequence number for POS orders that have sequence numbers',
    showTableMarker: 'Show Table Marker',
    showTableMarkerCaption: 'The KPS displays the table marker information.',
    showTimer: 'Show Timer',
    showTrainingLabel: 'Show "Training order" label',
    showTrainingLabelCaption:
      'Labels transactions from training exercises as “*TRAINING*”. This prevents these orders from being prepared. Make sure “',
    showTrainingLabelCaption2: 'Show training orders',
    showTrainingLabelCaption3: '” is On to enable this option (see the ',
    showTrainingLabelCaption4: 'Orders',
    showTrainingLabelCaption5: ' tab)',
    showTrainingOrders: 'Show training orders',
    showTransactionNumber: 'Show transaction number',
    showTransactionNumberCaption:
      'Shows the id number for the transaction. This is the number on the virtual receipt.',
    showTransactionState: 'Show transaction state',
    showTransactionStateCaption:
      'Displays the state of the transaction (Active, Stored, Tendered, Canceled, Totaled)',
    showVehicleDescription: 'Show vehicle description',
    status: 'Status : {{status}}',
    transactionBumpOnStatusOverride: 'Override when orders can be bumped',
    transactionBumpOnStatusOverrideCaption:
      'This overrides the “When orders can be bumped” setting in KPS ',
    transactionBumpOnStatusOverrideCaption2: 'Global Settings',
    transactionBumpOnStatusOverrideCaption3:
      '. An order can be bumped from a KPS when it reaches this stage in a transaction. This is typically set to “Tendered”, but some Operators prefer the ability to bump orders before then.',
    transactionTotalLabel: 'Transaction Total',
    windowGridAdmin: 'Window Grid (Admin)',
  },
  LogFile: {
    header: 'Log File',
  },
  MultipleItemColorsDetails: {
    backgroundColor: 'Background Color',
    blinkerText: 'Blink item',
    cancel: 'Cancel',
    closeText: 'Close',
    confirm: 'Reset {{number}} item colors',
    header: 'Multiple Item Colors',
    itemListModalTitle: 'Items selected',
    moreButtonText: '+{{number}} more',
    resetConfirmationModalBody:
      'This will reset colors on all sizes of these items back to their original colors.',
    resetConfirmationModalTitle: 'Reset colors for {{number}} items?',
    resetToDefault: 'Reset {{number}} items to default colors',
    textColor: 'Text Color',
  },
  NotFoundPage: {
    delivery: 'CFA Delivery',
    errorDescription: 'Error code 404',
    errorTitle: 'Page not found',
    help: 'Help',
    home: 'Home',
    kps: 'KPS',
    menu: 'Menu',
    ordering: 'Ordering',
    storeListing: 'Store Listing',
  },
  OrderColors: {
    alertButtonText: 'Adjust Caution and Alert times in',
    backgroundColor: 'Background Color',
    changeColorText: 'Change colors of',
    header: 'Order Detail Colors',
    items: 'items',
    resetToDefault: 'Reset all order color settings',
    selectDestination: 'Destination',
    statusText: 'Order Status',
    textColor: 'Text Color',
    transactionButtonText: 'Transaction Alerts',
    viewAndEdit: 'Select detail to edit',
  },
  OrderDetailColors: {
    blinkerText: 'Blink item',
    cancelledItem: 'Cancelled Item',
    clothing: 'Clothing',
    deferredOrderTenderStatus: 'Deferred Order Tender Status',
    destination: 'Destination',
    freeItem: 'Free Item',
    guestCount: 'Guest Count',
    guestInstructions: 'Guest Instructions',
    guestName: 'Guest Name',
    headerArea: 'Header Area',
    lot42: 'Lot 42',
    lowestSequence: 'Lowest Sequence',
    modifiedQuantities: 'Modified Quantities',
    orderDetailColorsHeader: 'Preview',
    orderDetails: '1 Nugget 12ct',
    orderStatus: 'Order Status',
    recoveryMessage: 'Recovery Message',
    sequence: 'Sequence',
    tableMarker: 'Table Marker',
    timer: 'Timer',
    trainingLabel: 'Training Label',
    vehicleDescription: 'Vehicle Description',
  },
  QuickActions: {
    addToCart: 'Add to cart',
    buttonName: 'Quick item route change',
    during: 'during',
    iWant: 'I want',
    on: 'on',
    searchText: 'Search item',
    toDisplay: 'to display',
  },
  ReturnToService: {
    header: 'Return to Service',
  },
  RouteValidation: {
    activityLevelLabel: 'Activity Level(s)',
    emptyText: 'Select an item and filters to see routes',
    kpsLabel: 'KPS(s)',
  },
  SaveButton: {
    save: 'Save',
  },
  ScreenShots: {
    errorText: 'Error retrieving screenshot. Could not contact the store.',
    refreshButtonText: 'Refresh Screenshots',
    view: 'View',
  },
  SelectSPFlexTerminalsModal: {
    close: 'Cancel',
    note: 'Note: Changes here can affect other KPSs. All KPSs that have “Print Customer Receipt” turned On are linked to these devices',
    selectSpFlexTerminals: 'Select iPad POS(s)',
    update: 'Update',
  },
  Status: {
    header: 'Status',
  },
  SystemTools: {
    header: 'System Tools',
  },
  TransactionAlerts: {
    alertTimeLabel: 'Alert Time',
    alertTimeSublabel: 'In minutes and seconds',
    cautionTimeLabel: 'Caution Time',
    cautionTimeSublabel: 'In minutes and seconds',
    displaySecondaryTimerHelpText:
      'Shows an additional timer in the header area of each KPS window. The timer indicates the amount of time elapsed since the order was Totaled. Many restaurants use this to measure customer window time in the Drive-Thru.',
    displaySecondaryTimerLabel: 'Display Secondary Timer',
    jumpToLabel: 'Jump to:',
    minutesLabel: 'Minutes',
    secondsHelperText: '0-59 seconds',
    secondsLabel: 'Seconds',
    secondsPlaceholder: '0 seconds',
    settingAccordionLabel: '{{name}} Transaction Alerts',
    startTimerFromStateOverrideHelpText:
      'Determines when the transaction timers for this Order Type are set to begin. Most are set to Active, but you may wish to override this. This can be changed globally in Global Settings > Bump Bar',
    startTimerFromStateOverrideLabel:
      'Start Timer from (order state) - Override',
    startTimerFromStateOverridePlaceholder: 'Select a status',
    subtitle: 'Configure Transaction Alerts for each Destination',
    timeHelperText: '0-60 minutes',
    timePlaceholder: '1 minute',

    title: 'Transaction Alerts',
  },
  Troubleshoot: {
    header: 'Troubleshoot',
  },
  ViewRouting: {
    activityLevel: 'Activity Level',
    ActivityLevelModal: {
      allRoutingSettings:
        'All routing settings will be configured for the following activity level',
      selectAnActivityLevel: 'Select an activity level',
    },
    activityLevelName: 'Activity Level : {{activityLevelName}}',
    change: 'Change',
    closeText: 'Close',
    description: 'Select items to move',
    hideComboToggleTitle: 'Hide combo headers for mobile and POS orders',
    items: 'items',
    message: '*Some items above may still show - ',
    messageModalBody1:
      '“Show all items in POS orders” is turned On for this station and there are POSs routed to this view',
    messageModalBody2:
      '“Show all items in mobile orders” is turned On for this station and POS 40 is routed to this view',
    messageModalTitle: 'Why some items may still show',
    moveSelected: 'Move {{selectedItemsAmount}} selected',
    MoveSelectedModal: {
      always: 'Always',
      cancel: 'Cancel',
      description: 'Select when to show',
      move: 'Move',
      moveItems: 'Move {{selectedItemsAmount}} Items',
      never: 'Never',
      selectWhenToShowAllSelectedItems:
        'Select when to show all selected items',
      whenModified: 'When modified',
    },
    seeDetailButton: 'See details',
    select: 'Select',
    selectAll: 'Select all',
    unselectAll: 'Unselect all',
  },
};

import { z } from 'zod';

export const activityLevelSchema = z.object({
  activityLevelId: z.number(),
  activityLevelName: z.string(),
  default: z.boolean(),
  hasError: z.boolean().optional(),
  errMessage: z.string().optional(),
  copiedFromId: z.union([z.number(), z.null()]),
  lastUpdatedAt: z.number(),
});

type ActivityLevel = z.infer<typeof activityLevelSchema>;

export default ActivityLevel;

import { z } from 'zod';

export const destinationColorSchema = z.object({
  destinationId: z.number(),
  foreColorId: z.number(),
  backColorId: z.number(),
  blinking: z.boolean(),
});

type DestinationColor = z.infer<typeof destinationColorSchema>;

export default DestinationColor;

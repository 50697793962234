import { z } from 'zod';

export const itemDisplayTypeSchema = z.object({
  sourceNodeNumber: z.number(),
  itemDisplayType: z.number(),
});

type ItemDisplayType = z.infer<typeof itemDisplayTypeSchema>;

export default ItemDisplayType;

import { z } from 'zod';

const storeSettingsSchema = z.object({
  apiUrl: z.string(),
  uiUrl: z.string(),
  betaStore: z.boolean(),
  kpsConfigEnabled: z.boolean(),
  posConfigEnabled: z.boolean(),
  fullServeStore: z.boolean(),
  lastExportTimestamp: z.date().optional(),
  lastPollReceived: z.number().optional(),
});

const storeClientSchema = z.object({
  timeZone: z.string(),
  timeZoneOffset: z.number(),
  spVersion: z.string().optional(),
});
export const spcStoreSchema = z.object({
  number: z.number(),
  name: z.string(),
  address: z.string(),
  city: z.string(),
  state: z.string(),
  zipCode: z.string(),
  country: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  gmtOffset: z.string(),
  timeZoneName: z.string(),
  timeZoneId: z.string(),
  locationStatus: z.string(),
  hasBeenInitialized: z.number(),
  statuses: z.string(),
  storeSettings: storeSettingsSchema,
  storeClient: storeClientSchema,
  features: z.array(z.string()).optional(),

  searchWeight: z.number().optional(),
  searchIndex: z.number().optional(),
  searchResult: z.string().optional(),
});

type SpcStore = z.infer<typeof spcStoreSchema>;

export default SpcStore;

import { z } from 'zod';

export const stationSchema = z.object({
  stationNumber: z.number(),
  storeNumber: z.string(),
  stationName: z.string(),
});

type Station = z.infer<typeof stationSchema>;

export default Station;

import { z } from 'zod';

export const colorSchema = z.object({
  colorId: z.number(),
  colorName: z.string(),
  hexColor: z.string(),
});

type Color = z.infer<typeof colorSchema>;

export default Color;

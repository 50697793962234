import { useEffect, useState } from 'react';
import { useUserPermissions } from '@cfacorp/ctrl-platform-shared-react-components';

const useHasKpsPerms = () => {
  const cfaPerms = useUserPermissions();
  const [hasKpsPerms, setHasKpsPerms] = useState(true);
  const [permsLoaded, setPermsLoaded] = useState(false);

  useEffect(() => {
    if (cfaPerms) {
      const isAuthorized =
        !!cfaPerms.SP_CONFIG?.SUPER_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.KPS_ADMIN?.length ||
        !!cfaPerms.SP_CONFIG?.KPS_USER?.length;
      setHasKpsPerms(isAuthorized);
      setPermsLoaded(true);
    }
  }, [cfaPerms]);

  return { hasKpsPerms, permsLoaded };
};

export default useHasKpsPerms;

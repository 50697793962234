import { z } from 'zod';
import { colorDetailSchema } from './ColorDetail';

export const transactionColorDetailSchema = z.object({
  transactionDetailLabel: z.union([z.string(), z.null()]),
  lastUpdatedAt: z.number(),
  lastUpdatedBy: z.string(),
  skipModifiedDateCheck: z.boolean(),
  skipExistCheck: z.boolean(),
  transactionDetailId: z.number(),
  headerArea: colorDetailSchema,
  destination: colorDetailSchema,
  register: colorDetailSchema,
  timer: colorDetailSchema,
  terminalNumber: colorDetailSchema,
  employeeName: colorDetailSchema,
  transactionNumber: colorDetailSchema,
  trainingLabel: colorDetailSchema,
  activeStatus: colorDetailSchema,
  storedStatus: colorDetailSchema,
  recalledStatus: colorDetailSchema,
  totaledStatus: colorDetailSchema,
  seatTotaledStatus: colorDetailSchema,
  tenderedStatus: colorDetailSchema,
  voidedStatus: colorDetailSchema,
  deferredOrderTenderStatus: colorDetailSchema,
  tableName: colorDetailSchema,
  serverName: colorDetailSchema,
  deliveryZone: colorDetailSchema,
  guestName: colorDetailSchema,
  clothing: colorDetailSchema,
  vehicleDescription: colorDetailSchema,
  tableMarker: colorDetailSchema,
  guestCount: colorDetailSchema,
  pager: colorDetailSchema,
});

type TransactionColorDetail = z.infer<typeof transactionColorDetailSchema>;

export default TransactionColorDetail;

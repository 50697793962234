import KpsChange from '@cfacorp/ctrl-platform-ui-core-utils/dist/types/KpsChange';

export interface KpsGroup {
  groupName: string;
  settingGroups: {
    settingChanges: {
      change: string;
      settingName: string;
    }[];
    settingGroupName: string;
  }[];
}

interface Grouping {
  [key: string]: string[];
}

interface SettingGrouping {
  [key: string]: KpsChange[];
}

const createBackupPayload = (changes: KpsChange[]) => {
  const groupings: Grouping = {};
  const settingsGroupings: SettingGrouping = {};
  const kpsGroups: KpsGroup[] = [];

  changes.forEach(change => {
    groupings[change.topLevelGroupName] = groupings[change.topLevelGroupName]
      ? [...groupings[change.topLevelGroupName], change.settingGroupName]
      : [change.settingGroupName];
    settingsGroupings[change.settingGroupName] = settingsGroupings[
      change.settingGroupName
    ]
      ? [...settingsGroupings[change.settingGroupName], change]
      : [change];
  });

  Object.entries(groupings).forEach(([key, value]) => {
    const settingGroups = value.map(settingGroupName => ({
      settingGroupName,
      settingChanges: settingsGroupings[settingGroupName].map(
        ({ settingName, message }) => ({
          settingName,
          change: message,
        }),
      ),
    }));
    kpsGroups.push({
      groupName: key,
      settingGroups,
    });
  });

  return kpsGroups;
};

export default createBackupPayload;
